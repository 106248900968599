import PropTypes from 'prop-types';
import { Edit } from '../../assets/icons';

const FormEditableTitle = ({ onClickEdit, editMode, title }) => {
  return (
    <div className="form-editable-title">
      <div className="form-editable-title__text first-letter">{title}</div>
      <div className="form-editable-title__edit">
        <Edit
          size="large"
          className={`form-edit-trigger ${editMode ? 'active' : ''}`}
          onClick={onClickEdit}
        />
      </div>
    </div>
  );
};
FormEditableTitle.propTypes = {
  onClickEdit: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.number
  ])
};
FormEditableTitle.defaultProps = {
  editMode: false,
  title: undefined
};

export default FormEditableTitle;
