import PropTypes from 'prop-types';
import { Form as FormAntd } from 'antd';
import FormEditableTitle from './components/FormEditableTitle';

const { Item, useForm } = FormAntd;

const Form = props => {
  const {
    children,
    layout,
    initialValues,
    onValuesChange,
    onFinish,
    onFinishFailed,
    className,
    wrapperCol,
    labelCol,
    form,
    preserve
  } = props;
  Form.useForm = FormAntd.useForm();
  return (
    <div className={`form ${className}`}>
      <FormAntd
        layout={layout}
        labelCol={labelCol}
        wrapperCol={wrapperCol}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
        preserve={preserve}
      >
        {children}
      </FormAntd>
    </div>
  );
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  layout: PropTypes.string,
  initialValues: PropTypes.objectOf(Object),
  onValuesChange: PropTypes.func,
  onFinish: PropTypes.func,
  onFinishFailed: PropTypes.func,
  className: PropTypes.string,
  wrapperCol: PropTypes.objectOf(Object),
  labelCol: PropTypes.objectOf(Object),
  preserve: PropTypes.bool,
  form: PropTypes.oneOfType([PropTypes.func, PropTypes.objectOf(Object)])
};

Form.defaultProps = {
  layout: 'horizontal',
  initialValues: undefined,
  onValuesChange: undefined,
  onFinish: undefined,
  onFinishFailed: undefined,
  className: '',
  wrapperCol: undefined,
  labelCol: undefined,
  preserve: true,
  form: undefined
};

export { Form, Item as FormItem, useForm, FormEditableTitle };
